.App {
  font-family: sans-serif;
  text-align: center;
  position: relative;
  height: 100vh;
  width: 100%; /* Default for desktop */
  background-color: white;
}

@media (max-width: 768px) {
  .App {
    width: fit-content; /* Apply for smaller screens (e.g., tablets, mobile) */
  }
}
