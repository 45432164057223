/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
  }
  
  h1 {
    text-align: center;
    margin: 20px 0;
    color: #444;
  }
  
  /* Container */
  .job-board-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 20px auto;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive Grid Layout */
  .job-board {
    display: grid;
    grid-template-columns: 1fr; /* Single column on small screens */
    grid-gap: 1px;
  }
  
  @media (min-width: 768px) {
    .job-board {
      grid-template-columns: 30% 70%; /* Sidebar and Details */
    }
  }
  
  /* Job List Styles */
  .job-list {
    background-color: white;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    max-height: 500px;
  }
  
  .job-list-item {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
  }
  
  .job-list-item:hover {
    background-color: #eef3fc;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .job-list-item.active {
    background-color: #d6e4f8;
    font-weight: bold;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .job-title {
    font-size: 16px;
    color: black;
    margin: 0;
  }
  
  .job-company {
    font-size: 14px;
    color: #666;
    margin: 5px 0;
  }
  
  .job-location {
    font-size: 12px;
    color: #999;
  }
  
  /* Job Details Styles */
 /* Ensure you have FontAwesome added to your project for icons */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

.job-details {
  font-family: 'Arial', sans-serif;
  background-color: #fff;
  /* padding: 20px; */
  /* border-radius: 8px; */
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  margin-top: 20px;
  max-width: 800px;
  margin-left: 20px;
  margin-right: 20px;
}

.job-details h2 {
  font-size: 2rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 15px;
}

.job-details .info {
  font-size: 0.8rem;
  color: #555;
  line-height: 1;
  margin-bottom: 15px;
  font-weight: normal;
  display: flex;
  align-items: center;
}

.job-details .info i {
  margin-right: 8px; /* Space between the icon and text */
}

.job-details .info br {
  display: block;
  margin-bottom: 8px;
}

.job-details p {
  font-size: 1.1rem;
  color: #444;
  line-height: 1.8;
  margin-bottom: 20px;
}

.job-details .chip {
  background-color: #1976d3;
  color: white;
  padding: 8px 15px;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 1rem;
  margin-bottom: 12px;
  display: inline-block;
}

.job-details .info span {
  font-weight: bold;
  color: #333;
}

.job-details .button-wrapper {
  text-align: center;
}

.job-details button {
  background-color: #1976d3;
  color: white;
  padding: 12px 25px;
  font-size: 1.2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 15px;
  font-weight: bold;
  max-width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.job-details button:hover {
  background-color: #1976d3;
}

.job-details .job-title,
.job-details .company-name {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 5px;
}

.job-details .location {
  font-size: 1.2rem;
  color: #1976d3;
  margin-bottom: 15px;
  font-weight: bold;
}

.job-details .job-description {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  font-size: 1.1rem;
  color: #444;
  line-height: 1.6;
}

.job-details .job-description p {
  margin-bottom: 12px;
}

.job-details .job-description p:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .job-details {
    padding: 15px;
    margin-top: 10px;
  }

  .job-details h2 {
    font-size: 1.6rem;
  }

  .job-details button {
    font-size: 1rem;
  }

  .job-details .chip {
    padding: 6px 12px;
    font-size: 0.9rem;
  }
}

.job-infor {
    width: 30%;
    margin-right: 2%; /* Optional: Space between the two sections */
    display: inline-block;
    vertical-align: top;
  }
  
  .job-description {
    width: 58%;
    display: inline-block;
    vertical-align: top;
  }
  
  .job-infor {
    margin-left: 3%; /* Space between the two sections */
  }
