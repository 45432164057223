input[type="text"],
/* input[type="password"], */
input[type="number"],
input[type="date"],
textarea{
    width: 70%;
    padding: 10px;
    margin-top: 10px;
    /* border: 1px solid #ccc; */
    /* border-radius: 5px; */
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}