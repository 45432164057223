.resetpassword {
    margin:70px;
    display: flex;
    justify-content: center; 
    align-items: center;    
    height: 70vh;           
    background-color: white; 
  }
  
  .flex-item {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;     
    width: 100%;
    max-width: 400px;        
    padding: 20px;
    background-color: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .custom-input {
    margin-bottom: 15px;
  }
  
  .resetpassword button {
    width: 50%;
    padding: 10px;
    /* background-color: blue; */
    /* color: white; */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* button:hover {
    background-color: darkblue;
  } */
  
/* .error-p {
    color: red;
  } */
  