
.employ {
  display: flex;
  align-items: flex-start; 
  justify-content: space-between; 
  flex-wrap: wrap; 
}

.employ-column {
  width: 48%; 
}

button {
  padding: 10px;
  font-size: 16px;
  background-color: #2840b7;
  color: white;
  border: none;
  cursor: pointer;
  margin-left:50%;
  width: 20%; 
}

input[type="text"], textarea, select {
  width: 100%; 
  padding: 10px;
  margin: 10px 0; 
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

textarea {
  resize: none; 
}

.experience-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.experience-group input {
  width: 48%; 
}.employ {
  display: flex;
  align-items: flex-start; 
  justify-content: space-between; 
  flex-wrap: wrap; 
}

.employ-column {
  width: 48%; 
}

.jobs button {
  padding: 10px;
  font-size: 16px;
  background-color: #2840b7;
  color: white;
  border: none;
  cursor: pointer;
  margin-left:20%;
  width: 20%; 
}

.jobs input[type="text"], textarea, select {
  width: 70%; 
  padding: 10px;
  margin: 10px 0; 
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

textarea {
  resize: none; 
}

.experience-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.experience-group input {
  width: 48%; 
}
.employ-row {
  display: flex;
  gap: 1rem; 
  margin-bottom: 1rem; 
}

.employ-row input,
.employ-row select {
  flex: 1; 
  padding: 0.5rem; 
  border: 1px solid #ccc; 
  border-radius: 4px; 
}

textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
}
