
.custom-input input {
  border: 2px solid #ccc; 
  padding: 12px; 
  border-radius: 8px; 
  font-size: 16px; 
  transition: all 0.3s ease; 
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); 
  outline: none !important;
}


.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important; 
}


.custom-input label {
  font-size: 14px; 
  color: #666; 
}


.custom-input:hover input {
  border-color: #007BFF; 
}


.custom-input input:focus {
  border-color: #007BFF !important; 
  background-color: #fff; 
  outline: none !important; 
  box-shadow: 0 0 6px rgba(0, 123, 255, 0.4); 
}


.MuiMenuItem-root {
  font-size: 26px;
}


.custom-input select {
  
  border-radius: 8px;
  padding: 10px;
  border: 2px solid #ccc;
  transition: all 0.3s ease;
  outline: none !important; 
}


.custom-input:hover select {
  border-color: #007BFF;
}


.custom-input select:focus {
  border-color: #007BFF;
  box-shadow: 0 0 6px rgba(0, 123, 255, 0.4);
  outline: none !important; 
}


.MuiDialog-paper {
  padding: 20px;
  max-width: 900px; 
  margin: auto;
}


.MuiButton-root {
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.custom-input1 .MuiOutlinedInput-root {
  background-color: #fff; 
  border-radius: 8px;
  border: 2px solid #ccc; 
}


.custom-input1 .MuiOutlinedInput-root.Mui-focused {
  border-color: #007BFF; 
}


.MuiMenuItem-root {
  font-size: 16px;
}