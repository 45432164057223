.email_button{
    background-color: #007bff;
    color: white;
    padding: 8px 15px; 
    border: none;
    
    cursor: pointer;
    font-size: 14px;
    height:40px;
    margin-left: 0%;
  }

.email_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; 
    text-align: center;
    background-color: #f8f9fa;
}