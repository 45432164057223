.main-container {
  display: flex;
  justify-content: space-between;
  align-items: left;
  text-align: left;
  margin-left: 10%;
  margin-right: 10%;
  max-width: 100%;
  height: 350px;
  overflow: hidden;
  
}
.my-container {
  max-width: 100%;
  height:auto;
  background-color: white;
  overflow-y: hidden; 
  overflow-x: hidden; 
}

.my-container button {
  margin-top: 30px;
  width: 50%;
  background-color: blue;
  color:white
}
.my-container button:hover{
background-color: rgb(19, 19, 141);
}

/* body {
  overflow: hidden;
} */
.search {
  width: 50%;
  padding: 20px;
  margin-top: 20%;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
  .image-zoom-container {
    /* overflow: hidden; */
    position: relative;
    width: 100%; 
  }
  .main-container-leftside h1{
    color:#881008;
  }
  
  .zoomed-image {
    width: 100%;
    height: 350px;
    
    transform-origin: center;
    
  } 
  @keyframes zoom-in {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1); 
    }
}
.main-container{
  display: flex;
    justify-content: space-between;
    align-items: left;
    text-align: left;
   margin-left: 10%;
   margin-right: 10%;
   height:550px;
   width:50%;
}
.about-v{
  padding: 50px 0px;
    background-color: rgb(255, 255, 255);
    
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}
.m-row{
  display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-left: 6%;
}
.m-row1 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.vision{
  margin-top: -10%;
}
.mission{
  margin-top: -10%;
}
.m-text{
  padding-top: 5px;
}
.m-text h3 {
  font-size: 32px;
  color: #000;
  font-weight: 300 !important;
  margin-top:-2% ;
}
.ser-box-button a {
  display: block; 
  width: 100%;
  height: 100%;
  text-decoration: none; 
  color: inherit; 
}
.m-text p{
  color: rgb(118, 118, 118);
    text-align: justify;
    width:90%;
    margin-left: 3%;
}
.vision h4{
  font-family: 'Oswald', sans-serif;
    color: #000000;
    font-weight: 600;
    font-size: 32px;
}
.vision p{
  color: rgb(118, 118, 118);
    text-align: justify;
    margin-top: -5%;
}
.mission h4{
  font-family: 'Oswald', sans-serif;
  color: #000000;
  font-weight: 600;
  font-size: 32px;
}
.mission p{
  color: rgb(118, 118, 118);
    text-align: justify;
    margin-top: -5%;
}
.m-button{
  z-index: 1;
    height: 30px;
    width: 120px;
    color: #fff;
    font-weight: 600;
    line-height: 30px;
    padding-right: 35px;
    border-radius: 30px;
    position: relative;
    font-family: "Oswald", sans-serif;
    background: #881008;
    padding: 10px 20px;
    border: 1px solid #fff;
    transition: 0.5s ease;
    font-size: 18px;
}
.m-button a{
  display: block; 
    width: 100%;
    height: 100%;
    text-decoration: none; 
    color: inherit;
}
.about-home-img {
  padding: 50px 0px;
    background-color: rgb(255, 255, 255);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.2));
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}
.company_name{
  font-size: x-large;
}
.main_img1{
  width: 290px;
  height: 240px;
  border: 1px solid #eaeaea;
  background: #fff;
  padding: 5px;
  border-radius: 4px;
  object-fit: cover;
  position: absolute;
  margin-left: -15%;
  margin-top: -5%;
}

.main_img2{
  width: 300px;
    height: 250px;
    object-fit: cover;
    position: absolute;
    
    border: 1px solid #eaeaea;
    
    background: #fff;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: 4px;
    margin-top: 4%;
    margin-left: -6%;
}
.m-services{
  padding: 30px 0px;
    overflow: hidden;
    position: relative;
}
.m-container1, .m-container1-fluid, .m-container1-lg, .m-container1-md, .m-container1-sm, .m-container1-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.m-service-heading h2{
  font-family: 'Oswald', sans-serif;
    margin-top: -5%;
    color: #881008;
    font-size: 30px;
    font-weight: 500;
    padding: 25px 0px 30px 0px;
    text-align: center;
    font-weight: bold !important;
}
.m-heading-ser{
  font-weight: 700;
    position: relative;
    z-index: 2;
    text-decoration: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
    display: inline;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: color 1s ease;
}

.m-3 .m-6{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.m-3 {
  flex: 0 0 calc(25% - 30px); 
  max-width: calc(25% - 30px); 
  padding: 0 15px;
}
.m-4 {
  flex: 0 0 calc(40% - 30px); 
  max-width: calc(40% - 30px); 
  padding: 0 15px;
}
.ser-box {
  display: flex;
  flex-direction: column;
  height: 100%; 
}

.ser-box img{
  width: 94%;
    margin: 0 auto;
    clip-path: polygon(0 0, 100% 0%, 100% 93%, 0% 100%);
    display: flex;
    border-radius: 12px 12px 0px 0px;
    height:200px;
}
.ser-box-text{
  background-color: #fff;
    border: 1px solid #eee;
    padding: 50px 12px 12px 12px;
    margin-top: -40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.ser-box-text h3 {
  font-size: 16px;
  color:#881008;
  margin: 0px;
  padding: 0px;
  text-align: left;
}
.ser-box-button{
  z-index: 1;
    height: 30px;
    width: 120px;
    color: #fff;
    font-weight: 600;
    line-height: 31px;
    padding-right: 35px;
    border-radius: 30px;
    position: relative;
    font-family: "Oswald", sans-serif;
    background: linear-gradient(98deg, #881008 1%, #c44b4b 100%);
    padding: 6px 13px;
    border: 1px solid #fff;
    transition: 0.5s ease;
    font-size: 12px;
    transition: 0.5s ease;
}
.ser-box-text p{
  text-align: left;
  margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
}
.main-bridge1{
  height:160px;
}
.main-bridge2{
  height:160px;
}
.meet_our_space {
  padding: 150px 0px 80px 0px;
  background-image: linear-gradient(45deg, #eee0, #fff0), url(../../images/yellow_banner1.jpg);
  
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  margin: 0 auto;
  height:500px;
}
.m-container2{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.meet_our_space::after {
  position: absolute;
  content: '';
  bottom: 0px;
  left: 500px;
  width: 1450px;
  height: 635px;
  background-color: #eaeaea;
  z-index: 0;
  border-top-left-radius: 35px;
}
.m-row2{
  display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.meet_our_space_row {
  position: relative;
  z-index: 9;
}
.wedding_hands {
  position: relative;
}
.wedding_hands img{
  height: 250px;
  margin-top: 50%;
}
.meet_our_space_lft_cnt {
  margin-top: 40%;
}
.meet_our_space_lft_cnt h4{
  font-family: 'Oswald', sans-serif;
  margin-top: -10%;
  color: #881008;
  font-size: 40px;
  font-weight: 700;
  margin-left: 50%;
}
.abt_sec_home_btn {
  z-index: 1;
  height: 30px;
  width: 120px;
  color: #fff;
  font-weight: 600;
  line-height: 30px;
  padding-right: 35px;
  border-radius: 30px;
  position: relative;
  font-family: "Oswald", sans-serif;
  background: linear-gradient(98deg, #881008 1%, #c44b4b 100%);
  padding: 10px 20px;
  border: 1px solid #fff;
  transition: 0.5s ease;
  font-size: 18px;
}
.abt_sec_home_btn1 {
  z-index: 1;
  height: 30px;
  width: 120px;
  color: #fff;
  font-weight: 600;
  line-height: 30px;
  padding-right: 35px;
  border-radius: 30px;
  position: relative;
  font-family: "Oswald", sans-serif;
  background: linear-gradient(98deg, #881008 1%, #c44b4b 100%);
  padding: 10px 20px;
  border: 1px solid #fff;
  transition: 0.5s ease;
  font-size: 18px;
  margin-left: 50%;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev:before, .slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: #881008 !important;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: #ff0089 !important;
  margin-top: 30%;
}
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: #ff0089 !important;
}
.meet_our_space_slicbox img {
  width: 115%;
  margin: 0px 5px;
  height: 300px;
  margin-top: 20%;
  margin-left: 10%;
}
.our-clients-main-container {
  padding: 40px 0px 70px 0px;
  background: #f8f9fa;
  overflow: hidden;
}
.m-container3{
  width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.m-row3{
  display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}
.ourclients-main-heading-sec {
  text-align: center;
  margin-bottom: 35px;
}
.ourclients-main-heading-sec h2 {
  font-family: 'Oswald';
  margin-top: 10px;
  color: #881008;
  font-size: 25px;
}
.ourclients-main-heading-sec p {
  padding: 3px 450px;
}
.clientsview-fist-row-sec {
  animation: 30s linear 0s infinite normal none running animate_row1;
  -ms-animation: 30s linear 0s infinite normal none running animate_row1;
  margin-bottom: 40px;
}
.clients-logos-section {
  display: flex;
}
.client-sec {
  padding: 0px 20px;
  margin-right: 28px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 5px;
  background: #fff;
}
.client-sec img {
  /* filter: grayscale(1); */
  transition: .3s ease-in-out;
  width: 100px;
}
.clientsview-fist-row-sec {
  animation: 30s linear 0s infinite normal none running animate_row1;
  -ms-animation: 30s linear 0s infinite normal none running animate_row1;
  margin-bottom: 40px;
}
@keyframes animate_row1 {
0% {
  transform: translateX(20%) translateZ(0px);
}
100% {
  transform: translateX(0) translateZ(0px);
}
}
.clientsview-second-row-sec {
  animation: 30s linear 0s infinite normal none running animate_row2;
  -ms-animation: 30s linear 0s infinite normal none running animate_row2;
}
@keyframes animate_row2 {
  0% {
      transform: translateX(-20%) translateZ(0px);
  }
  100% {
      transform: translateX(0) translateZ(0px);
  }
  }
  .testimonial_sec {
    padding: 70px 0px;
    background: #ffffff;
    overflow: hidden;
    position: relative;
}
.m-container4{
  width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.m-row4{
  display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.testimonial_heading{
  margin-left: 25%;
}
.testimonial_heading h2 {
  font-family: 'Oswald';
  margin-top: 10px;
  color: #881008;
  font-size: 25px;
  text-align: center;
  font-weight: 700;
  margin-left: 32%;
}
.owl-carousel {
  display: none;
  position: relative;
  width: 100%;
}
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}
.owl-carousel {
  display: inherit !important;
}
.owl-carousel .owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.testimonial {
  margin: 50px 30px 30px 30px;
  text-align: center;
  position: relative;
}
.testimonial .description {
  padding: 30px;
  margin-bottom: 20px;
  border-left: 1px solid #881008;
  font-size: 15px;
  color: #727272;
  line-height: 33px;
  position: relative;
}
.testimonial .title {
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: #881008;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 15px 0;
  position: relative;
}
.testimonial .pic img {
  display: inline-block;
  width: 100px;
  height: 100px;
}
.testimonial:before, .testimonial .title:before {
  content: "";
  width: 1px;
  height: 20px;
  background: #dd097b;
  margin: 0 auto;
  position: absolute;
  top: -30px !important;
  left: 0;
  right: 0;
}
.testimonial:before, .testimonial .title:before {
  content: "";
  width: 1px;
  height: 20px;
  background: #881008;
  margin: 0 auto;
  position: absolute;
  top: -30px !important;
  left: 0;
  right: 0;
}
.testimonial .description:after {
  background: #881008;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20%;
}

.testimonial .description:after {
  bottom: 0;
  top: auto;
}
:after, :before {
  box-sizing: border-box;
}
.testimonial .title {
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: #881008;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 15px 0;
  position: relative;
}

.testimonial .description:before, .testimonial .description:after {
  content: "";
  width: 20%;
  height: 1px;
  background: #881008;
  position: absolute;
  top: 0;
  left: 0;
}

.testimonial {
  margin: 50px 30px 30px;
  position: relative;
  text-align: center;
}
.testimonial:before, .testimonial .title:before {
  content: "";
  width: 1px;
  height: 20px;
  background: #881008;
  margin: 0 auto;
  position: absolute;
  top: -30px !important;
  left: 0;
  right: 0;
}

.round-button {
  background-color: #fff;
  border: 2px solid #881008;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  font-size: 16px;
  color: #881008;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  padding-left: 15px;
}

.round-button.active {
  background-color: #881008;
  color: #fff;
}
.testimonial .title:before{ top: -80px; }
.round-button:hover {
  background-color: #881008;
  color: #fff;
}
.testimonial-buttons{
  margin-left: 30%;
}
.wding_cps {
  position: relative;
  height: 400px;
}
.wding_cps img {
  margin: -15% -52% 0px -35px;
  height: 480px;
}
.m-5 .sm12{
  position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.m-7 .sm12{
  position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
@media (max-width: 768px) {
  .my-container {
    padding: 0 5%;
  }
  .zoomed-image {
    width: 120%;
    /* height: 200px; */
    margin-left: -10%;
    margin-top: 2%;
  }
  .MuiGrid-container {
    flex-direction: column;
    gap: 15px;
  }

  .MuiFormControl-root {
    width: 100%; /* Ensures dropdowns take full width */
  }

  .hovered-item-box {
    min-width: 300px;
    max-width: 90%;
  }
  .my-container button{
    margin-top: -25%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .my-container {
    padding: 0 7%;
  }
  .zoomed-image {
    width: 120%;
    height: 500px;
    margin-left: -10%;
  }
  .MuiGrid-container {
    flex-direction: column;
    gap: 10px;
  }
}
@media (max-width: 960px) { /* Small and medium screens */
  .scroll-container {
    max-height: 80vh; /* Adjust height */
    overflow-x: auto; /* Enable vertical scrolling */
  }
}
@media (max-width: 960px) { /* Small and medium screens */
  .scroll-item {
    white-space: nowrap;
    overflow-x: auto;
  }
}
@media (max-width: 360px) {
  .my-container button {
      margin-top: 10%;
      
  }
  .zoomed-image {
    width: 120%;
    height: 500px;
    margin-left: -10%;
  }
}
@media (min-width: 509px) and (max-width: 560px) {
  .my-container button {
      margin-top: -17%;
  }
  .zoomed-image {
    width: 120%;
    height: 500px;
    margin-left: -10%;
  }
}
@media (min-width: 769px) and (max-width: 899px) {
  .my-container button {
      margin-top: -17%;
  }
  .zoomed-image {
    width: 120%;
    height: 500px;
    margin-left: -10%;
  }
}
/* @media (min-width: 900px) and (max-width: 1024px) {
  .my-container button {
      margin-top: -17%;
  }
} */
@media (min-width: 900px) and (max-width: 1024px) {
  .my-container button {
      margin-left: -17%;
      white-space:nowrap ;
      width:90%;
  }
}