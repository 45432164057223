

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  }
  
  .profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .profile-card {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    min-width: 950px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .profile-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }
  
  .profile-image {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: 5px solid  #7883c0;
    margin-bottom: 20px;
  }
  
  .profile-name {
    font-size: 28px;
    color: #333;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .profile-bio {
    font-size: 16px;
    color: #777;
    margin-bottom: 20px;
  }
  
  .profile-info {
    margin-top: 20px;
    text-align: left;
    margin-left: 20%;
  }
  .profile-info1 {
    margin-top: 20px;
    text-align: left;
    margin-left: 10%;
  }
  
  .info-item {
    margin-bottom: 10px;
    font-size: 15px;
  }
  
  .info-label {
    font-weight: bold;
    color: #333;
  }
  
  .info-value {
    margin-left: 10px;
    color: #555;
  }
  
  .edit-button {
    
    margin:20px;
    
    background: linear-gradient(45deg,  #7883c0, #7b77ac);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 150px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: 0.3s ease, box-shadow 0.3s ease;
  }
  
  .edit-button:hover {
    background: linear-gradient(45deg, #7883c0, #7b77ac);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  .camera-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .camera-icon:hover {
    transform: scale(1.2);
  }
  
  .camera-icon svg {
    color: #000; 
    font-size: 20px; 
  }
  .image-upload{
    display: inline;
  }
  .profile-image-container {
    position: relative;
    display: inline-block;
    width: 150px; 
    height: 150px;
  }
  

  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #ccc;
  }
  
  
  .camera-icon {
    position: absolute;
    top: 90%;
    /* right: -105px;  */
    transform: translateY(-50%);
    
    border-radius: 50%;
    padding: 5px;
    
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .camera-icon:hover {
    transform: translateY(-50%) scale(1.2);
  }
  
  .camera-icon svg {
    color: #000; 
    font-size: 20px; 
  }
