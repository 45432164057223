

.event-list-container {
    margin: 20px;
  }
  
  .event-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .event-table th, .event-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .event-table th {
    background-color: #f2f2f2;
  }
  
  .event-table tbody tr:hover {
    background-color: #f5f5f5;
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination li {
    list-style: none;
    margin: 0 5px;
    display: inline-block;
  }
  
  .pagination li a {
    text-decoration: none;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pagination li a:hover {
    background-color: #0056b3;
  }
  .switch-button {
    width: 40px;
    height: 15px;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    background-color: #ccc;
    overflow: hidden;
    margin-top: 4%;
  }
  
  .switch-button.on {
    background-color: #28a745;
  }
  
  .switch-button.off {
    background-color: #ff0000ba;
  }
  
  .switch-handle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 0;
    transition: transform 0.3s ease;
  }
  
  .switch-button.on .switch-handle {
    transform: translateX(30px);
  }
  .button-container {
    display: flex;
    margin-top: -2%;
  }
  
  .details-button {
    position: relative;
  }
  
  
  .details-button:hover .hover-text {
    display: block;
  }
  
  .chat-button{
    margin-left: 2%;
  }
  .chat-box-event-list{
    width: '120%';
     height: '50px'; 
     resize: 'vertical';
      overflow: 'auto';
  }
  .message-save-button{
    height: '10px';
    width:'20px';
    background-color: #28a745;
    border-radius: '50px';
  }
  .message-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .message p {
    margin: 0;
  }
  
  .timestamp p {
    margin: 0;
    font-size: 8px;
    color: #888;
  }
  .sticky-textarea-container {
    position: sticky;
    bottom: 0px;
    background-color: white;
    padding: 10px;
    border-top: 1px solid #ccc;
  }
  .event-list-container {
    position: relative;
    overflow: hidden;
  }
  
  .scrollable-content {
    max-height: calc(100vh - 150px); 
    overflow-y: auto;
  }
  .event_items_assign{
    color: #333; 
    font-size: 16px;
  }
  .event_items_assign_text {
    color: #333; 
    font-size: 16px; 
    height:20px;
    width:20px;
  }
  .e-date{
    white-space: nowrap;
  }
  .event_items_assign_text:hover {
    opacity: 1;
    color: #333;
  }
  .event_items_assign_icon{
    width:50px;
    height:15px;
    color:blue;
    margin-top: 15%;
    margin-left: 10%;
  }
  .event_items_assign_icon:hover{
    color:#881008;
  }
  .add-event-button{
    margin-left: -22%;
    margin-top: -0%;
  }
  @media (max-width: 600px){
    .event-table {
        overflow-x: auto;
        display: block;
        white-space: nowrap;
        width: 150%;
    }
    .pagination{
      margin-top: 2%;
    }
    .add-event-button{
      margin-left: 5%;
      white-space: nowrap;
  }
      }
