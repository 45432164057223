.admin-container {
    display: flex;
    overflow: hidden;
    flex-direction: row;
}

.sidebar {
    width: 300px;
    background-color: #333;
    color: white;
    padding: 20px;
    height: 810px;
    margin-top: 3.5%;
}

.admin-content {
    flex-grow: 1;
    padding: 20px;
    margin-top: 5%;
    
}


.sidebar ul {
    list-style: none;
    padding: 0;
}
.event-modal h2{
  margin-top: 5%;
}
.sidebar li {
    margin-bottom: 50px; 
    cursor: pointer;
    font-size: larger;
}


.sidebar li:hover {
    background-color: #555;
}
.add-event-button{
    margin-top: 0%;
    margin-right: 60%;
    display: inline-block;
}

.search-bar {
    margin-bottom: 20px;
    
}

.search-bar input {
    width: 20%;
    padding: 8px;
    margin-left: 50%;
    box-sizing: border-box;
    margin-top: 5%;
}

.search-bar button {
    padding: 10px;
    margin-left: 5px;
    cursor: pointer;
    display: inline-block;
}
.admin-button{
    margin-top: -8%;
}
.admin_input_1{
  width:20%;
}
.event-modal {
margin-top: 10px;

overflow-x: hidden;
}

.event-modal h2 {
    margin-bottom: 10px;
}
.event-input-box label {
    display: block;
    margin-bottom: 3px;
    margin-left: 15%;
    margin-top: 11%;
}

.event-input-box-location label{
    display: block;
    margin-bottom: 3px;
    margin-left: 0%;
    margin-top: 11%;   
}

.event-input-box1 label{
    margin-top: 11%;
    margin-right: 50%;
    margin-left: -82%;
}
.event-input-box2{
  margin-left: 1%;
}
.vendor-checkbox{
  margin-left: -20%;
}
.vendor-label{
  margin-left: 10%;
}
.e-pagination button{
padding: 10px; 
margin: 0.5%;
}
.vendor-checkbox2{
  margin-top: -1%;
  margin-left: 25%;
}
.admin_input_1{
    height:59px;
    
    padding: 20px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-top: 0%;
    margin-right: 80%;
    margin-left: 5%;  
}
.admin_label_1{
    padding:1px;
    margin-left: 5%;
    margin-top: 2%;
}
.admin_input_1{
  margin-left: 5%;
}
.admin-toggle-button button{
    background-color:#881008;
 
  padding: 10px;
  margin: 5px;
  border: none;
  cursor: pointer;
}
.admin-toggle-button{
    margin-top: 2%;
    margin-left: 2%;
}
.chairs-component-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0%;
    margin-left: 20%;
    width:10%;
  }
  .vender-select{
    margin-top: 2%;
    margin-left: -70%;
  }
  .event-input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
}
.selected {
  color: yellow; 
}
.event-input-box,
.event-input-box-location,
.event-input-box1 {
    flex: 1;
    margin-right: 10px; 
}
.event-input-box input {
    width: 70%;
    box-sizing: border-box;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.event-input-box-location input{
    width: 70%;
    box-sizing: border-box;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-left: -39%;
}
.event-input-box1 input {
    width: 70%;
    box-sizing: border-box;
    padding: 10px;
    margin-top:2%;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-left: -82%;
}
.chairs-component-container2 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    
    margin-left: 20%;
    width:10%;
  }
.chairs-component-container2 input{
margin: 2px;
}
.back-button {
    display: inline-flex;
    align-items: left;
    color: #4CAF50;
    text-decoration: none;
    font-size: 26px;
    margin-bottom: 10px;
    margin-left: -90%;
    background-color: rgb(222, 239, 241);
    border-radius: 50px;
    background-size: 50px;
    
  }
  
  .back-button:hover {
    color: #eaf4ec;
    background-color: #e8e2e4;
    border-radius: 50px;
  }
  .error-message2{
    margin-left: 0%;
  }
  .success-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #4CAF50;
    color: white;
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  
  .success-popup svg {
    font-size: 1.2rem;
  }
  
@media (max-width: 600px) {
    .admin-container {
      flex-direction: column;
    }
    
    .admin-content {
      margin-top: 10%;
    }
  
    .sidebar {
      width: 100%;
      margin-bottom: 20px;
      height:300px;
      margin-top: -2%;
    }
    .sidebar li{
        margin-left: -5%;
    }
    .sidebar li .active{
      color: yellow;
    font-size: x-large;
    }
    .admin_input_1 {
      margin-right: 5%;
      margin-left: 5%;
    }
  
    
    table {
      width: 100%; 
    }
   
  }

  @media (max-width: 768px) {
    .sidebar {
        margin-top: -1%;
        height:400px;
        width:100%
    }
    .sidebar ul{
        margin-top: 5%;
    }
    .admin-container {
      flex-direction: column;
  }
  .event-table {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
    width: 150%;
}
  }
  @media (min-width: 1024px) and (max-width: 1024px) {
    .admin-container {
      flex-direction: column;
    }
    .event-table {
      overflow-x: auto;
      display: block;
      white-space: nowrap;
      width: 150%;
  }
    .sidebar {
        margin-top: 6%;
        height:300px;
        width:100%;
    }
    .admin_input_1{
      width:30%;
    }
    .event-modal h2{
      margin-top: 15%;
    }
  }
  @media (min-width: 1280px) and (max-width: 1280px) {
    .sidebar {
        margin-top: 5%;
        height:750px;
    }
  }
  @media (max-width: 768px) {
    .event-input-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: -10%;
  }
  .event-input-box-location label{
    margin-left: 16%;
    margin-top: 2%;
  }
  .event-input-box-location input{
    margin-left: 2%;
  }
  .event-input-box1 label{
    margin-left: 10%;
    margin-top: 2%;
  }
  .event-input-box1 input{
    margin-left: 1%;
  }
  .admin_label_1{
    margin-top: 5%;
    margin-left: 15%;
  }
  .admin_input_1{
    width:70%;
  }
  .back-button{
    margin-left: -62%;
    font-size: 16px;
  }
  .add-event-button2{
    margin-left: -12%;
  }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    .event-input-box-location label{
      margin-left: 16%;
      margin-top: 2%;
    }
    .event-input-box1 label{
      margin-left: -5%;
      margin-top: 2%;
    }
    .admin_label_1{
      margin-top: 5%;
    }
    .admin_input_1{
      width:70%;
      margin-left: 15%;
    }
  }
  @media (min-width: 820px) and (max-width: 912px) {
    .event-input-box-location label{
      margin-top: 10%;
      margin-left: 1%;
    }
    .event-input-box1 label{
      margin-top: 10%;
      margin-left: -60%;
    }
    .admin_input_1 {
      width: 30%;
      margin-left: 5%;
  }
  .admin-container {
    flex-direction: column;
  }
  .event-table {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
    width: 150%;
}
.sidebar {
  margin-top: 0%;
  height:300px;
  width:100%;
}
  }
  
