.forgot_password_input{
    margin-top: 1%;
    width:50%;
    
    padding: 8px; 
    box-sizing: border-box;
    margin-bottom: 5px; 
    border-radius: 50px;
}
.forgot_password_label{
    color: black;
    margin-top: 20%;
    margin-left: 25%;
}
.reset_password_label1{
    color: #000000;
    margin-top: 10%;
    margin-left: 25%;
}
.reset_password_label2{
    color: black;
    margin-top: 3%;
    margin-left: 25%;
}
.reset_password_label3{
    color: black;
    margin-top: 3%;
    margin-left: 25%;
}
.reset_password_input1{
    width:50%;
    
    padding: 8px; 
    box-sizing: border-box;
    margin-bottom: 5px; 
    border-radius: 50px;
    height: 50px; 
}
.reset_password_input2{
    width:50%;
   
    padding: 8px; 
    box-sizing: border-box;
    margin-bottom: 5px; 
    border-radius: 50px;
    height: 50px; 
}
.reset_password_input3{
    width:50%;
    
    padding: 8px; 
    box-sizing: border-box;
    margin-bottom: 5px; 
    border-radius: 50px;
    height: 50px; 
}
.reset_password_button{
    margin-top: 1%;
}
@media screen and (max-width: 767px) {
    .forgot_password_input{
        width:80%;
        margin-left: 20%;
    }
    .rest_password_form{
        margin-top: 25%;
    }
    .reset_password_label1{
        margin-left: 5%;
    }
    .reset_password_input1{
        margin-left: 5%;
        width:100%;
    }
    .reset_password_label2{
        margin-left: 5%;
    }
    .reset_password_input2{
        margin-left: 5%;
        width:100%;
    }
    .reset_password_label3{
        margin-left: 5%;
    }
    .reset_password_input3{
        margin-left: 5%;
        width:100%;
    }
    .forgot_password_form{
        margin-top: 25%;
    }
   
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .reset-password-container{
        margin-top: 15%;
        overflow: hidden;
    }
    .reset_password_label1{
        margin-left: 5%;
    }
    .reset_password_input1{
        margin-left: 5%;
        width:100%;
    }
    .reset_password_label2{
        margin-left: 5%;
    }
    .reset_password_input2{
        margin-left: 5%;
        width:100%;
    }
    .reset_password_label3{
        margin-left: 5%;
    }
    .reset_password_input3{
        margin-left: 5%;
        width:100%;
    }
    .forgot_password_form{
        margin-top: 15%;
    }
    .forgot_password_input{
        width:70%;
        margin-left: 15%;
    }
   
}
@media screen and (min-width: 1280px) and (max-width: 1280px) {
.rest_password_form{
    margin-top: 10%;
}

}
@media screen and (min-width: 1024px) and (max-width: 1024px) {
    .reset-password-container{
        margin-top: 10%;
    }
   
}

@media screen and (min-width: 820px) and (max-width: 912px) {
    .forgot_password_input{
        width:100%;
        margin-left: 0%;
    }
    .forgot_password_label{
        margin-left: 5%;
    }
  
}

