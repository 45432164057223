
.password-container {
    position: relative;
    margin: 15px 0;
    width: 100%;
  }
  
  
  .password-input {
    width: 100%;
    padding: 10px 40px 10px 10px; 
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
  }
  

  .eye-icon {
    position: absolute;
    right: 10px;      
    top: 50%;     
    transform: translateY(-50%); 
    cursor: pointer;
    color: #333;
    font-size: 18px;
  }
  

.container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    background-color: #f4f6f9;
    /* padding: 20px; */
  }
  
  
  .container1 {
    display: flex;
    height:100%;
    flex-direction: row; 
    align-items: center; 
    justify-content: center; 
    width: 100%;
    max-width: 100%; 
  }
  
 
  .flex-item-login {
    flex: 1;
    text-align: center;
    padding: 20px;
    max-width: 500px; 
    margin: 20px;
    /* background-color: white; */
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  }
  
  .container-login input[type="text"],
  .container-login input[type="password"] {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  

.login-actions {
    display: flex;
    flex-direction: column;
    align-items: center;  
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
    width: 100%;
  }
  
  .login-actions button {
    padding: 12px 24px;
    background-color: #1976D2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 300px; 
    margin: 0 auto; 
  }
  
  .login-actions button:hover {
    background-color: #1565C0;
  }
  
  .login-actions button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .links-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .link-item {
    margin: 5px;
  }
  
  .links-container a {
    text-decoration: none;
    font-size: medium;
    color: blue;
  }
  
  .links-container a:hover {
    text-decoration: underline;
  }
  
  .flex-item-login img {
    width: 100%;
    max-width: 400px;
    height: auto;
    object-fit: contain; 
    padding: 20px;
    margin-top: 20px;
  }
  
  .full-page-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .error-message {
    color: red;
    font-family: serif;
    margin-top: 10px;
  }
  @media screen and (min-width: 480px) and (max-width: 767px) {
    .container1 {
      flex-direction: column;
      padding: 20px;
      margin-top: 25%;
    }
    .flex-item-login img{
      margin-top: -15%;
    }
    /* .flex-item-login {
      max-width: 100%;
      margin: 10px;
    }
  
    .links-container {
      flex-direction: column;
      gap: 10px;
    }
  
    .login-actions button {
      width: 100%;
    }
  
    .flex-item-login img {
      max-width: 300px;
      padding: 10px;
    } */
  }
  
  @media screen and (max-width: 479px) {
    .password-input {
      padding: 10px 35px 10px 10px;
      font-size: 14px;
    }
    .container1 {
      flex-direction: column;
      padding: 20px;
      margin-top: 25%;
    }
    .eye-icon {
      font-size: 16px;
    }
  
    .login-actions button {
      padding: 10px 20px;
      font-size: 14px;
    }
    .flex-item-login img{
      margin-top: -25%;
    }
    .links-container a {
      font-size: small;
    }
  }
  