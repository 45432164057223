.container-fp {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center; /* Center content */
  width: 100%;
  height: 100vh;
  background-color: white;
  overflow: hidden;
}

.flex-item-fp { 
  flex: 1;
  text-align: center;
  padding: 20px; /* Add padding for better spacing */
}

.container-fp input[type="text"] {
  width: 80%; 
  padding: 10px;
  margin: 10px 0; 
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.full-page-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.imageDetails {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  max-height: 400px; 
  object-fit: contain; 
}

.container-fp button {
  width: 80%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  background-color: blue;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.container-fp button:hover {
  background-color: darkblue;
}
@media (max-width: 767px) {
  .container-fp {
    flex-direction: column; /* Stack items vertically */
    margin-top: 15%;
  }
}
@media (max-width: 320px) {
  .container-fp {
    flex-direction: column; /* Stack items vertically */
    margin-top: 25%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .container-fp {
    flex-direction: row; /* Stack items vertically */
    margin-top: -25%;
  }
}
@media (min-width: 1024px) and (max-width: 1024px) {
  .container-fp {
    flex-direction: column; /* Stack items vertically */
    margin-top: 3%;
  }
    .imageDetails {
      display: none;
    }
}
