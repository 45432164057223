.container1 {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;  
    width: 90%;
    background-color: white;
}

.flex-item-login {
    flex: 1 1 50%; 
    height: 90%;
    text-align: center;
    margin: 20px 50px;
}

.imageDetails_login {
    width: 50%;  
    height: auto;
}




.container1 button {
    width: 80%;
    padding: 20px;
}
.button-row button{
    margin: 25px 15px;
    box-shadow: 4px -4px 5px rgba(0, 0, 0, 0.4);
  }
  .button-row button:hover {
    background-color: #406aa1;
    box-shadow: 8px -8px 7px rgba(0, 0, 0, 0.6);
    transform: scale(1.05);
  }
