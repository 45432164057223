.otpContainer {
    margin-top:10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
   
}

.otpContainer .card {
    border: 2px solid rgb(175, 169, 169);
    
    border-radius: 20px;
    padding: 50px;
   
}