.navbar {
  background-color: #1976D2;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px;
  position: fixed;
  flex-direction: row;
  
  width: 102%;
  top: 0;
  z-index: 1000;
}

.vender-button {
  background-color: #6200ea; /* Primary background color */
  color: #ffffff; /* Text color */
  border: 2px solid transparent; /* Transparent border for cleaner look */
  border-radius: 8px; /* Rounded corners */
  padding: 10px 20px; /* Padding for a balanced button size */
  font-size: 16px; /* Font size for readability */
  font-weight: bold; /* Bold text for emphasis */
  text-transform: uppercase; /* Optional: Capitalize text */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  cursor: pointer; /* Pointer cursor for clickability */
}

.vender-button:hover {
  width: fit-content;
  background-color: #261353; /* Darker shade for hover */
  color: #ffffff; /* Ensure text is readable */
 
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Enhance shadow on hover */
}

.vender-button:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 3px rgba(98, 0, 234, 0.5); /* Focus ring for accessibility */
  width: fit-content;
}

.vender-button:active {
  background-color: #311b92; /* Slightly darker shade on click */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow for pressed state */
}


.header-container {
  display: flex;
  
  align-items: center;
  background-color: white;
  color: #fff;
  padding: 5px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  margin-left: -1%;
  height: 30px;
}


.navbar-menu li .active {
  color: yellow; 
  font-size: x-large;
}


.navbar-logo {
  font-size: 24px;
}

.navbar-menu {
  list-style: none;
  display: flex;
  
}

.navbar-menu  li {
  margin-right: 10%;
  
  padding-left: 5%;
  
}

.navbar.scrolling {
  background-color: none; 
}
.navbar-menu a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
  text-decoration: none;
}

.navbar-menu li:hover {
  color: #FFFFFF;
  transform: scale(1.1);
  transition: transform 0.3s ease; 
}

/* .vender-button{
  margin-top: -5%;
  margin-bottom: -9%;
  font-weight: 600;
background-color: #FFFFFF;
color: #881008;
height:30px;
padding-bottom:25px;
}
.vender-button:hover{
  background-color: #FFFFFF;
color: #881008;

} */


.homebg{
  position: relative;
    z-index: 92;
}
.bg {
  width: 100%;
}
.h-logo {
  padding: 0px 0px 0px 0px;
  position: absolute;
  left: 20px;
  top: 20px;
}
.clearfix {
  zoom: 1;
}
.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.h-logo::after {
  position: absolute;
  top: -30px;
  left: -30px;
  width: 170px;
  height: 170px;
  content: '';
  z-index: 1;
  background-color: #881008;
  border-radius: 0px 50% 50% 50%;
  animation: scale1 4s linear infinite alternate-reverse;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  animation-name: shapeMover2;
}
.h-logo img {
  position: relative;
  z-index: 2;
  margin-left: -7px;
  margin-top: -7px;
  width: 130px;
  border-radius: 50%;
}


.profile-container1 {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px; 
  padding: 0; 
  margin: 0; 
}

.profile-avatar1 {
  width: 25px; 
  height: 25px; 
  border-radius: 50%; 
  object-fit: cover;
}

.profile-name1 {
  font-size: 10px; 
  line-height: 1; 
  margin: 0; 
}
.logo-container {
  display: flex;
  align-items: center;
  margin-left: 2%;
}

.header-logo {
  height: 50px;
  width: auto;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover; 
  border: 2px solid white;
  animation: moveRightAndBack 2s ease-in-out;
}
.navbar-menu {
  margin-left: 0; 
  display: flex;
  justify-content: flex-start; 
  align-items: center; 
  padding: 0; 
}

.navbar-menu li {
  margin-right: 70px; 
  margin-left: -5%;
}

@media (max-width: 768px) {
  .navbar-menu {
    flex-direction: column; 
    margin-left: 0; 
  }

  .navbar-menu li {
    margin: 15px 0; 
  }
}
@keyframes moveRightAndBack {
  0% {
    transform: translateX(0); /* Start at the original position */
  }
  50% {
    transform: translateX(200px); /* Move slightly to the right */
  }
  100% {
    transform: translateX(0); /* Return to the original position */
  }
}
.navbar-menu {
  display: flex;
  gap: 5px; /* Adjust this value to control the gap between elements */
  padding: 0;
  margin: 0;
  list-style: none;
}

.navbar-menu li {
  margin: 15px; 
  padding: 0; 
}

.vender-button {
  padding: 5px 10px; 
  font-size: 14px;  
}

.navbar {
  display: flex;
  align-items: center;
}
.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}
@media screen and (max-width: 768px) {
  .header-container {
    flex-direction: row;
  }

  .navbar {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
  }

  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px; /* Adjust based on your navbar height */
    left: 0;
    background-color: #1976d2;
    width: 100%;
    gap: 1px;
    padding: 1px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    z-index: 100;
  }
  .navbar-menu.open {
    display: flex;
  }
  .menu-toggle {
    display: flex;
    position: absolute;
    top: 15px;
    right: 20px;
    background: none;
    border: none;
    cursor: pointer; 
  }

  .menu-toggle .menu-icon {
    font-size: 30px;
    margin-top:-15%;
  }
  .navbar-menu a {
    font-size: 16px;
    padding: 10px 0;
  }
  .navbar-menu li {
    margin: 10px 0;
  }
  
}
@media (min-width: 769px) {
  /* Hide the menu toggle button on larger screens */
  .menu-toggle {
    display: none;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1280px) {
  .navbar {
    justify-content:left; 
  }
}
