
.tatal_footer_section{
  padding: 0px 0px 10px 0px;
    margin-top: 100px;
    background-image: linear-gradient(45deg, rgb(228 9 127 / 25%), rgb(170 7 95 / 20%));
    background-color: white;
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: left;
    margin-top: 0px;
    overflow: hidden;
}
.footer_section {
  position: relative;
  padding: 85px 0px 0px 0px;
}
.footer_container{
  max-width:1460px;
}
.footer_section::after {
  position: absolute;
  content: '';
  top: 35px;
  right:5%;
  width: 95%;
  border-radius: 0px 75px 0px 0px;
  height: 520px;
  background-color: #000;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.2));
  z-index: 0;
}
.f-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: 20%;
  align-items: center;
}
.footer_section_row {
  position: relative;
  z-index: 8;
}
.row-1{
    flex: 0 0 30%; 
    max-width: 30%; 
    margin-left: 0; 
  }
.v-logo{
  text-align: left;
  margin-left: -20%;
}
.v-logo img{
  width:50%;
  margin-left: 12%;
}
.footer-p{
  color:white;
}
.v-logo p{
text-align: left;
margin-left: -10%;
width:100%;
}
.footer-links h3{
  margin-top: 10px;
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  margin-left: -250%;
}
.footer-links1 h3{
  margin-top: 10px;
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  margin-left: -190%;
}
.footer-links{
  margin-left: 40%;
  white-space: nowrap;
  /* margin-top: -5%; */
}
.footer-links1{
  margin-left: 45%;
  white-space: nowrap;
}
.footer-links2{
  margin-left: 35%;
  white-space: nowrap;
}
.no-underline {
  text-decoration: none;
  color:white;
}
.footer-links ul li {
  list-style-type: none;
    margin: 15px;
    margin-bottom: 5px;
    color: #fff;
    text-align: left;
    margin-bottom: 6%;
    margin-left: -180%;
}
.footer-links1 ul li {
  list-style-type: none;
    margin: 0px;
    margin-bottom: 5px;
    color: #fff;
    text-align: left;
    margin-bottom: 6%;
    margin-left: -120%;
}
.footer-links2 ul li {
  list-style-type: none;
    margin: 0px;
    margin-bottom: 5px;
    color: #fff;
    text-align: left;
    margin-bottom: 6%;
      margin-left: -190%;
}
.footer-links ul li img {
  width: 20px;
  margin-right: 8px;
  transition: 0.5s ease;
  filter: brightness(100%) contrast(100%) saturate(100%) hue-rotate(33deg);
}
.footer-links1 ul li img {
  width: 20px;
  margin-right: 8px;
  transition: 0.5s ease;
  filter: brightness(100%) contrast(100%) saturate(100%) hue-rotate(33deg);
}
.footer-links2 ul li img {
  width: 20px;
  margin-right: 8px;
  transition: 0.5s ease;
  filter: brightness(100%) contrast(100%) saturate(100%) hue-rotate(33deg);
}
.footer_social_icons{
  margin-left: -250%;
}
.footer_social_icons img {
  width: 45px;
  transform: rotate(0deg);
  transition: 0.9s ease;
  margin-bottom: -30px;
  margin-left: 0%;
}
.foot_line {
  background-color: #fff;
  width: 105%;
  height: 1px;
  position: relative;
  z-index: 9;
  margin-left: -18%;
  margin-top: 10px;
  justify-content: center;
  margin-bottom: 15px;
}
.foot_line2 {
  background-color: #fff;
  width: 105%;
  height: 1px;
  position: relative;
  z-index: 9;
  margin-left: -18%;
  margin-top: -3%;
  justify-content: center;
  margin-bottom: 15px;
}
.contact-details h5 img{
  height: 30px;
  width: 30px;
  color: #e4097f;
  margin-top: 4%;
  font-size: 16px;
  margin-left: -63%;
}
.contact-details2 h5 img{
  height: 30px;
  width: 30px;
  color: #e4097f;
  margin-top: 4%;
  font-size: 16px;
  margin-left: -43%;
}
.contact-details1 h5 img{
  height: 30px;
  width: 30px;
  color: #e4097f;
  margin-top: 4%;
  font-size: 16px;
  margin-left: -67%;
}
.contact-details h5{
  display: flex; 
  margin-top: -6.2%;
}
.contact-details1 h5{
  display: flex; 
  margin-top: -6.2%;
}
.contact-details2 h5{
  display: flex; 
  margin-top: -6.2%;
}
.contact-details h5 p{
  color:#fff;
  padding-left: 5px;
}
.contact-details2 h5 p{
  color:#fff;
  padding-left: 5px;
}
.contact-details1 h5 p{
  color:#fff;
  padding-left: 5px;
  margin-top: 10%;
  font-size: larger;
}
.contact_details p {
  width: 300px;
  margin: 0px;
  margin-left: 2px;
  color: #ebebeb;
  font-weight: 400;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 8px;
  padding: 0px;
}
.contact_details1 p {
  width: 300px;
  margin: 0px;
  margin-left: 2px;
  color: #ebebeb;
  font-weight: 400;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 8px;
  padding: 0px;
}
.contact_details2 p {
  width: 300px;
  margin: 0px;
  margin-left: 2px;
  color: #ebebeb;
  font-weight: 400;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 8px;
  padding: 0px;
}
.contact-details{
  display: flex;
  margin-left: 7px;
  position: relative;
    z-index: 9;
}
.contact-details1{
  display: flex;
  margin-left: 7px;
  position: relative;
    z-index: 9;
}
.contact-details2{
  display: flex;
  margin-left: 7px;
  position: relative;
    z-index: 9;
}
.row-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.footer_right {
  position: relative;
  z-index: 9;
  margin-top: 10px;
}
.row-5{
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.footerbottom1 h5 {
  font-size: 14px;
  margin-top: -6%;
  margin-bottom: 1%;
  text-align: center;
  margin-left: 85%;
  white-space: nowrap;
  color: #fff;
}
@media (min-width: 992px){
.row-1 {
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 25%;
}
}
@media screen and (max-width: 1024px) {
  .footer_container{
    max-width: 720px
  }
  .footer_section::after {
    top: 25px;
    right: 20px;
    width: 1220px;
}
.v-logo img{
  width:40%;
  margin-top: -7%;
  margin-left: 2%;
}
.footer-p{
  margin-left: -6%;
  margin-right: 0%;
  font-size: 16px;
  text-align: left;
}
.v-logo p{
  text-align: left;
  margin-left: 2%;
  width: 80%;
}
.footer-links1{
  margin-left: 35%;
}
}
@media screen and (max-width: 767px) {
 
  .footer_container{
    max-width: 540px
  }
  .v-logo img{
    width:40%;
    margin-top: -7%;
    margin-left: 45%;
  }
  .v-logo p{
    text-align: left;
    margin-left: 42%;
    width:50%;
  }
  .footer-p{
    margin-left: 36%;
    margin-right: 10%;
    font-size: 16px;
    text-align: left;
  }
  .footer-links h3 {
    text-align: left;
    margin-left: -42%;
  }
  .footer-links1 h3 {
    text-align: left;
    margin-left: -88%;
  }
  .footer_section::after {
    position: absolute;
    content: '';
    height: 1290px;
}
.row-1 {
  flex: 0 0 80%; 
  max-width: 100%; 
}

.footer-links ul li {
  margin-left: -88%;
  margin: 7px;
}
.footer-links ul  {
  margin-left: -70%;
}
.footer-links2 ul li {
  margin-left: -100%;
}
.footer-links1 ul li {
  margin-left: -250%;
}

.footer_social_icons{
  margin-left: -65%;
  margin-top: -5%;
}
.foot_line {
  margin-top: 5%;
  width:70%;
  margin-left: 1%;
}
.foot_line2 {
  width:70%;
  margin-left: 1%;
}
.footerbottom1 h5{
  color: #fff;
  text-align: left;
  margin-left: 1%;
}
.contact-details h5 p{
font-size: 15px;
white-space: nowrap;
padding: 0px;
margin-left: 5%;
margin-top: -3%;
font-weight: 200;
}
.contact-details1 h5 p{
  font-size: 15px;
  white-space: nowrap;
  padding: 0px;
  margin-left: 5%;
  margin-top: 7%;
  color: #fff;
  font-weight: 200;
  }
  .contact-details2 h5 p{
    font-size: 15px;
    white-space: nowrap;
    padding: 0px;
    margin-left: 2%;
    margin-top: -2%;
    color: #fff;
    font-weight: 200;
    }
.contact-details h5 img {
  margin-left: -25%;
  margin-top: -5%;
}
.contact-details1 h5 img {
  margin-left: -35%;
  margin-top: -0%;
}
.contact-details2 h5 img {
  margin-left: -19%;
  margin-top: -5%;
}
.contact-details h5{
  margin-top: -5%;
  margin-left: -20%;
}
.contact-details1 h5{
  margin-top: -5%;
  margin-left: -20%;
}
.contact-details2 h5{
  margin-top: -5%;
  margin-left: -20%;
}
.f-row {
  flex-direction: column;
  margin-left: -10%;
}
.footer-phone-icon{
  margin-top: -115%;
}
.row-4 {
  padding: 0px;
}
}


@media screen and ((min-width: 576px)) {
  .row-2{
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and ((min-width: 992px)) {
  .row-3{
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .footer_section::after {
    height: 1020px;
}
.footer-p {
  margin-left: 0%;
  margin-right: 0%;
  font-size: 16px;
  text-align: left;
  width:80%;
}

.footer-links1 h3 {
  margin-left: -176%;
}
.footer-links1 ul li {
  margin-left: -110%;
}
.footer-links h3 {
  text-align: left;
  margin-left: -96%;
}
.footer-links ul li {
  text-align: left;
  margin-left: -148%;
}

.footer-links2 ul li {
  text-align: left;
  margin-left: -110%;
}
.footer_social_icons{
  margin-left: -125%;
  margin-top: -3%;
}
.foot_line {
  margin-left: -20%;
}
.foot_line2 {
  margin-left: -20%;
}
.contact-details h5 img {
  margin-left: -64%;
  margin-top: 5%;
}
.contact-details h5 p {
  margin-left: -2%;
  color: #fff;
  font-weight: 200;
}
.contact-details1 h5 img {
  margin-left: -75%;
  margin-top: 5%;
}
.contact-details1 h5 p {
  margin-left: -5%;
  color: #fff;
  font-weight: 200;
}
.contact-details2 h5 img {
  margin-left: -35%;
  margin-top: 5%;
}
.contact-details2 h5 p {
  margin-left: -2%;
  color: #fff;
  font-weight: 200;
}
.footerbottom1 h5{
  margin-left: 20%;
}
}
@media screen and (min-width: 1280px) and (max-width: 1280px) {
  .v-logo img{
    width:40%;
    margin-top: -7%;
    margin-left: 5%;
  }
  .footer-p {
    margin-left: -40%;
    margin-right: 0%;
    font-size: 16px;
    text-align: left;
    width:100%;
  }
  .footer-links1 h3 {
    margin-left: -236%;
  }
  .footer-links1 ul li {
    margin-left: -160%;
  }
  .footer-links h3 {
    margin-left: -236%;
  }
  .footer-links ul li {
    margin-left: -160%;
  }
  
  .footer-links2 ul li {
    margin-left: -160%;
  }
  .footer_social_icons{
    margin-left: -175%;
    margin-top: -3%;
  }
  .foot_line {
    margin-left: -20%;
    width:108%;
  }
  .foot_line2 {
    margin-left: -20%;
    width:108%;
  }
  .contact-details h5 img {
    margin-left: -64%;
    margin-top: 5%;
  }
  .contact-details h5 p {
    margin-left: 2%;
    color: #fff;
    font-weight: 200;
  }
  .contact-details1 h5 img {
    margin-left: -75%;
    margin-top: 9%;
  }
  .contact-details1 h5 p {
    margin-left: -2%;
    color: #fff;
    font-weight: 200;
    padding: 5px;
  }
  .contact-details2 h5 img {
    margin-left: -50%;
    margin-top: 5%;
  }
  .contact-details2 h5 p {
    margin-left: 2%;
    color: #fff;
    font-weight: 200;
  }
  .footerbottom1 h5{
    margin-left: 60%;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1024px) {
  .v-logo img{
    width:70%;
    margin-top: -7%;
    margin-left: -30%;
  }
  .footer-p {
    margin-left: -30%;
    margin-right: 0%;
    font-size: 16px;
    text-align: left;
    width:120%;
  }
  .v-logo p{
    text-align: left;
    margin-left: -20%;
    width:120%;
  }
  .footer-links1 h3 {
    margin-left: -106%;
  }
  .footer-links1 ul li {
    margin-left: -140%;
  }
  .footer-links h3 {
    margin-left: -36%;
  }
  .footer-links ul li {
    margin-left: -10%;
    margin: 6px;
  }
  .footer-links ul{
    margin-left: -115%;
  }
  
  .footer-links2 ul li {
    margin-left: 10%;
    margin-top: 12%;
  }
  .f-row {
    margin-left: 25%;
  }
  .footer_social_icons{
    margin-left: 45%;
    margin-top: -3%;
  }
  .foot_line {
    margin-left: -20%;
    margin-right: -20%;
    width:198%;
  }
  .foot_line2 {
    margin-left: -20%;
    margin-right: -20%;
    width:198%;
  }
  .contact-details h5 img {
    margin-left: -44%;
    margin-top: 5%;
  }
  .contact-details h5 p {
    margin-left: 2%;
    color: #fff;
    font-weight: 200;
  }
  .contact-details1 h5 img {
    margin-left: -65%;
    margin-top: 9%;
  }
  .contact-details1 h5 p {
    margin-left: -2%;
    color: #fff;
    font-weight: 200;
    padding: 5px;
  }
  .contact-details2 h5 img {
    margin-left: -0%;
    margin-top: 5%;
  }
  .contact-details2 h5 p {
    margin-left: 2%;
    color: #fff;
    font-weight: 200;
  }
  .footerbottom1 h5{
    margin-left: 60%;
  }
}
@media screen and (max-width: 540px){
.v-logo img {
    width: 40%;
    margin-top: -1%;
    margin-left: 40%;
}
.v-logo p {
  margin-left: 35%;
}
.footer-links1{
margin-left: 35%;
}
.footer-links{
  margin-left: 35%;
  }
}
